import { ChakraProvider } from "@chakra-ui/react";
import Head from "next/head";
import Router from "next/router";
import { NextPage } from "next/types";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import NProgress from "nprogress";
import { ReactElement, ReactNode } from "react";
import Modal from "react-modal";
import { SWRConfig } from "swr";
import type { AppProps } from "next/app";
import { start } from "../bugsnag";
import theme from "../theme";

import "../styles/globals.css";

import "@fontsource/raleway/400.css";
import "@fontsource/raleway/700.css";
import "nprogress/nprogress.css";

start();

Modal.setAppElement("#__next");

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// global fetcher
const fetcher = (url: string) => fetch(url).then(res => res.json());

// polyfill Object.hasOwn for react-markdown
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  session: Session;
  Component: NextPageWithLayout;
};

const App = ({ Component, session, pageProps }: AppPropsWithLayout): JSX.Element => {
  const getLayout = Component.getLayout || (page => page);

  return (
    <>
      <Head>
        <title>Zero to Finals</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>

      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            {getLayout(<Component {...pageProps} />)}
          </SWRConfig>
        </ChakraProvider>
      </SessionProvider>
    </>
  );
};

export default App;
