import { extendTheme } from "@chakra-ui/react";

// eslint-disable-next-line camelcase
import { Open_Sans } from "next/font/google";

const openSans = Open_Sans({ subsets: ["latin"] });

const theme = extendTheme({
  fonts: {
    body: `'Raleway', sans-serif`,
    heading: `'Raleway', sans-serif`,
    openSans: openSans.style.fontFamily,
  },
  components: {
    Button: {
      baseStyle: {
        paddingY: 2,
        borderRadius: 20,
        whiteSpace: "normal",
      },
    },
    Modal: {
      baseStyle: {
        header: {
          pt: 6,
        },
        body: {
          px: 6,
          pb: 6,
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          border: "none",
        },
        button: {
          borderRadius: 20,
          p: 5,
          mb: 2,
          bg: "gray.50",
          transition: "background-color 150ms ease-in-out",
          _hover: {
            bg: "gray.200",
          },
          _expanded: {
            bg: "gray.200",
          },
        },
        icon: {
          mr: 2,
        },
        panel: {
          px: 0,
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          boxShadow: "none",
          borderRadius: 20,
          bg: "gray.100",
          cursor: "pointer",
        },
        body: {
          display: "flex",
          height: "100%",
          minHeight: 50,
          position: "relative",
          p: [4, null, 6],
          borderRadius: 20,
          transition: "background-color 150ms ease-in-out",
          _hover: {
            bg: "gray.200",
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tablist: {
          display: "inline-flex",
          boxShadow: `0 0 13px 0 var(--chakra-colors-gray-300)`,
          borderRadius: 40,
          border: "none",
          gap: 1,
          py: 1,
          px: 1,
        },
        tab: {
          _selected: {
            color: "white",
            bg: "blue.500",
            _hover: {
              bg: "blue.500",
            },
          },
          _hover: { bg: "gray.100" },
          borderRadius: 20,
          fontWeight: "bold",
          color: "gray.500",
          px: 5,
          m: 0,
          border: "none",
        },
        tabpanel: {
          px: 0,
          pt: 10,
        },
      },
    },
    Table: {
      baseStyle: {
        tbody: {
          td: {
            py: 2,
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        label: {
          // https://github.com/chakra-ui/chakra-ui/issues/2925
          touchAction: "none",
        },
      },
    },
  },
});

export default theme;
